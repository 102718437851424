(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/poker-paper/assets/javascripts/poker-paper.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/poker-paper/assets/javascripts/poker-paper.js');
"use strict";


const {
  forwardRef
} = React;
const PokerPaper = forwardRef((_ref, ref) => {
  let {
    children,
    className
  } = _ref;
  const classList = ['poker-paper', 'elevation-300'];
  if (className) {
    classList.push(className);
  }
  return React.createElement("div", {
    className: classList.join(' '),
    ref: ref
  }, children);
});
setGlobal('svs.poker_react.components.PokerPaper', PokerPaper);

 })(window);